<template>
  <div>
    <vue-goodshare-facebook
        page_url="https://www.facebook.com/TrackBox"
        title_social="Facebook"
        has_icon
    ></vue-goodshare-facebook>
    <vue-goodshare-email
        page_url="https://members.trackbox.world"
        mail_subject="Check this out"
        title_social="Email"
        has_icon
    >
    </vue-goodshare-email>
    <vue-goodshare-telegram
        page_url="https://members.trackbox.world"
        title_social="Telegram"
        has_icon></vue-goodshare-telegram>
    <vue-goodshare-whats-app
        page_url="https://members.trackbox.world"
        title_social="WhatsApp"
        has_icon></vue-goodshare-whats-app>
  </div>
</template>

<script>

import VueGoodshareFacebook from "vue-goodshare/src/providers/Facebook.vue";
import VueGoodshareTelegram from "vue-goodshare/src/providers/Telegram";
import VueGoodshareEmail from "vue-goodshare/src/providers/Email";
import VueGoodshareWhatsApp from "vue-goodshare/src/providers/WhatsApp";
import VueGoodshareTwitter from "vue-goodshare/src/providers/Twitter";

export default {
  components: {
    VueGoodshareFacebook,
    VueGoodshareTelegram,
    VueGoodshareEmail,
    VueGoodshareWhatsApp,
    VueGoodshareTwitter
  },
  name: "SocialSharingWidget"
}
</script>

<style scoped>

</style>