


















































































// @ts-ignore -- this doesn't have typings so implicit 'any' will have to be ok.
import * as VueGridLayout from "vue-grid-layout";

import { vxm } from "@/store";
import { Component, Vue } from "vue-property-decorator";

import GenericGridCard from "@/views/dashcards/GenericGridCard.vue";
import SocialSharingWidget from "@/widgets/SocialSharingWidget.vue";
import {DashCardData} from "@/views/DashCardData";
import {TicketDTO} from "../../../shared_vue/openapi/myvaultapi";

@Component({
  components: {
    GenericGridCard,
    SocialSharingWidget,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
  },
})
export default class Dashboard extends Vue {
  private ui = vxm.ui;
  private profile = vxm.profile;
  private publicPath = process.env.VUE_APP_STATIC_DIR;
  private auth = vxm.auth;

  private readonly layoutKey = "myvault-dash-layout";
  private dragging: boolean = false;
  private storedLayout = JSON.parse(localStorage[this.layoutKey] || null);

  // private layout: Array<DashCardData> = [];
  private gridKey = 0;
  private  showShare = false;
  private readonly catOptions = ['Admin and App Support', 'Accounts', 'General Feedback', 'Suggestions', 'Complaint', 'Compliment', 'Testimony'];
  private showFeedback = false;
  private selectedCat = '';
  private feedbackMessage = '';
  private saveGrid() {
    this.storedLayout = this.layout;
    localStorage.setItem(this.layoutKey, JSON.stringify(this.layout));
  }

  private submitFeedback(){
    console.log('saving...')
    
    //add waiting modal
    let newTicketDTO = <TicketDTO>{};
    newTicketDTO.ownerId = this.auth.UserId;
    newTicketDTO.title="Feedback";
    newTicketDTO.description=this.newDescription;
    //category and rest
    // newTicketDTO.category=this.newCat.value; // should we use ID? idk
    // this.helpdeskAPI.helpDeskNewHelpDeskRequest(newTicketDTO).then(result=>{
    //   console.log(result)
    //   if (result.status==200) console.log('yay!')
    // }).catch(err=>console.log(err)).finally(()=>{this.dismiss();
    //   this.fetchAll();});    
  }
  private shareme(){
    this.showShare = true;
  }
  
  private victimsupport(){
    window.location.href='https://trackbox.world/specialised-victim-support-svs/'
  }
  
  private get layout(){
    return this.ui.currentLayout;
  }
  private set layout(value){
    console.log('ignore')
  }

  private resetLayout(type: any) {
    // this.gridKey++;
    // this.layout = type === "base" ? this.Cards : this.storedLayout;
  }

  private noDrag() {
    this.ui.setDragging(false);
  }
  private moveEvent(i: any, newX: any, newY: any): any {
    this.ui.setDragging(true);
  }

  private movedEvent(i: any, newX: any, newY: any): any {
    setTimeout(this.noDrag, 250);
  }
}
